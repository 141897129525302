export enum LocationType {
  ExplorationMap = 1,
  Numbers,
  DiceArea,
  ObservationNumber,
  Observations,
  ObservationScores,
  ExpeditionNode,
  OperatorChoice,
  Path,
  PathwayScore,
  AreaScore,
  SubTotalScore,
  TotalScore,
  DangerTick,
  PlayerIdentity
}
